import { Tag } from 'antd';
import dayjs from 'dayjs';
import { PaperTypeEnum } from 'src/api/types/exam';
import type {
  QueryTableColumnProps,
  QueryTableFilterISchemaProperties,
} from 'src/components/QueryTable';

export const columns: QueryTableColumnProps = [
  {
    title: '试卷名称',
    key: 'paperName',
    dataIndex: 'paperName',
    width: 150,
  },
  {
    title: '试卷类型',
    key: 'paperType',
    dataIndex: 'paperType',
    width: 80,
    render: (type: PaperTypeEnum) => {
      return type === PaperTypeEnum.exam ? '考试' : '问卷';
    },
  },
  {
    title: '试卷分数',
    key: 'versions?.paperScore',
    width: 80,
    render: (_score: number, row: any) => {
      return `${row?.versions?.paperScore ?? '--'}分`;
    },
  },
  {
    title: '随机题目',
    key: 'versions.randomQuestion',
    dataIndex: 'versions.randomQuestion',
    width: 80,
    render: (_random: boolean, row: any) => {
      return <Tag color="blue">{row?.versions?.randomQuestion ? '是' : '否'}</Tag>;
    },
  },
  {
    title: '随机选项',
    key: 'versions.randomOption',
    dataIndex: 'versions.randomOption',
    width: 80,
    render: (_random: boolean, row: any) => {
      return <Tag color="blue">{row?.versions?.randomOption ? '是' : '否'}</Tag>;
    },
  },
  {
    title: '试卷描述',
    key: 'paperDescription',
    width: 120,
    dataIndex: 'paperDescription',
  },
  {
    title: '创建时间',
    key: 'createTime',
    width: 100,
    dataIndex: 'createTime',
    render: (time: string) => {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];

export const schema: QueryTableFilterISchemaProperties = {
  paperName: {
    type: 'string',
    title: '试卷名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入试卷名称',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
};

export const createSchema: QueryTableFilterISchemaProperties = {
  paperName: {
    type: 'string',
    title: '试卷名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入试卷名称',
    },
    'x-validator': [{ required: true, message: '请输入试卷名称' }],
  },
  paperType: {
    type: 'string',
    title: '试卷类型',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择试卷类型',
      options: [
        { label: '考试', value: PaperTypeEnum.exam },
        { label: '问卷', value: PaperTypeEnum.questionnaire },
      ],
    },
    'x-validator': [{ required: true, message: '请选择试卷类型' }],
  },
  paperDescription: {
    type: 'string',
    title: '试卷描述',
    'x-component': 'Input.TextArea',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入试卷描述',
    },
  },
};

export const shareSchema: QueryTableFilterISchemaProperties = {
  userId: {
    type: 'number',
    title: '分享人',
    'x-component': 'UserSearch',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入分享人用户名',
    },
    'x-validator': [{ required: true, message: '请输入分享人用户名' }],
  },
};

export const historyColumns: QueryTableColumnProps = [
  {
    title: '版本ID',
    key: 'paperVersionId',
    dataIndex: 'paperVersionId',
    width: 100,
  },
  {
    title: '是否发布',
    key: 'published',
    dataIndex: 'published',
    width: 40,
    render: (published: boolean) => {
      return <Tag color={published ? 'green' : 'red'}>{published ? '是' : '否'}</Tag>;
    },
  },
  {
    title: '试卷总分',
    key: 'paperScore',
    dataIndex: 'paperScore',
    width: 50,
    render: (score: number) => {
      return `${score}分`;
    },
  },
  {
    title: '题目顺序随机',
    key: 'randomQuestion',
    dataIndex: 'randomQuestion',
    width: 50,
    render: (random: boolean) => {
      return <Tag color="blue">{random ? '是' : '否'}</Tag>;
    },
  },
  {
    title: '题目选项随机',
    key: 'randomOption',
    dataIndex: 'randomOption',
    width: 50,
    render: (random: boolean) => {
      return <Tag color="blue">{random ? '是' : '否'}</Tag>;
    },
  },
  {
    title: '考试时长',
    key: 'duration',
    dataIndex: 'duration',
    width: 50,
    render: (duration: boolean) => {
      return `${duration ?? 0}分钟`;
    },
  },
  {
    title: '考试时长',
    key: 'createTime',
    dataIndex: 'createTime',
    width: 50,
    render: (time: string) => {
      return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];
