import { message, Modal } from 'antd';
import type { ModalProps } from 'antd';
import { Input, Select } from '@formily/antd-v5';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { pick } from 'lodash-es';
import { IPaperItem, ICreatePaper, IUpdatePaper } from 'src/api/types/exam';
import { Form, FormRef } from 'src/components/Form';
import { createSchema } from './config';
import { useEffect, useRef } from 'react';
import { createTestpaper, updateTestpaper } from 'src/api/clients';

interface IProps extends ModalProps {
  data?: IPaperItem | null;
  onRefresh?: () => void;
  onCancel?: () => void;
}

export function PaperModal(props: IProps) {
  const { open, onCancel, data, onRefresh, ...rest } = props;
  const [loading, { setTrue, setFalse }] = useBoolean(false);

  const formRef = useRef<FormRef | null>(null);

  const isEdit = !!data?.paperId;

  const onCancelHandle = useMemoizedFn(() => {
    const instance = formRef?.current?.getFormInstance();
    instance?.reset();
    onCancel?.();
  });

  const onSubmit = useMemoizedFn(async () => {
    const instance = formRef?.current?.getFormInstance();
    const formData = await instance?.submit<ICreatePaper>();
    const request = isEdit ? updateTestpaper : createTestpaper;
    const params = {
      ...formData,
    } as IUpdatePaper;
    if (isEdit && data?.paperId) {
      params['paperId'] = data.paperId;
    }
    setTrue();
    try {
      await request(params);
      message.success(`${isEdit ? '编辑' : '创建'}试卷成功`);
      onRefresh?.();
      onCancelHandle();
    } catch (err) {
      message.error(err as any);
    } finally {
      setFalse();
    }
  });

  useEffect(() => {
    const instance = formRef?.current?.getFormInstance();
    if (instance && data) {
      instance.setValues(pick(data, ['paperName', 'paperType', 'paperDescription']));
    }
  }, [data]);

  return (
    <Modal
      title={`${isEdit ? '编辑' : '创建'}试卷`}
      open={open}
      onCancel={onCancelHandle}
      confirmLoading={loading}
      onOk={onSubmit}
      {...rest}>
      <Form
        className="py-2"
        layout={{ labelWidth: 95 }}
        schema={createSchema}
        ref={formRef}
        components={{ Input, Select }}
        hideAction
      />
    </Modal>
  );
}
