import { Button, Card, message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  getPaperVersionHistory,
  getTestpaperList,
  publishVersion,
  deletePaperVersion,
} from 'src/api/clients/exam';
import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { schema, columns, historyColumns } from './config';
import { Input } from '@formily/antd-v5';
import { useMemo, useState } from 'react';
import { IPaperItem, IPaperVersionItem } from 'src/api/types/exam';
import { AiOutlinePlus } from 'react-icons/ai';
import { PaperModal } from './paper-modal';
const ExamPaperTableName = 'ExamPaperTableName';
const ExamPaperTableVersionName = 'ExamPaperTableVersionName';

export default function TestPaperList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visible, { setTrue, setFalse }] = useBoolean(false);
  const [versionVisible, { setTrue: setVersionVisibleTrue, setFalse: setVersionVisibleFalse }] =
    useBoolean(false);
  const [currentData, setCurrentData] = useState<IPaperItem | null>(null);

  const getTestPapers = useMemoizedFn(async (params) => {
    const { offset, limit, paperName } = params;
    const result = await getTestpaperList({
      offset,
      limit,
      paperName,
    });
    return {
      data: result.rows,
      total: result.count,
    };
  });

  const getPaperVersion = useMemoizedFn(async (params) => {
    const { offset, limit } = params;
    const { rows, count } = await getPaperVersionHistory({
      paperId: currentData?.paperId || '',
      offset,
      limit,
    });
    return {
      data: rows,
      total: count,
    };
  });

  const onDeleteDataByIds = useMemoizedFn(async (ids: string[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        console.log(ids);
        // await deleteQuestionLibrary(ids);
        message.success('删除成功');
        onRefresh();
      },
    });
  });

  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      ExamPaperTableName,
    );
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns,
      {
        title: '操作',
        fixed: 'right',
        width: 150,
        renderType: 'Operation',
        renderProps: (col: IPaperItem) => {
          return {
            buttons: [
              {
                label: '编辑',
                onClick: () => {
                  setTrue();
                  setCurrentData(col);
                },
              },
              {
                label: '配置',
                onClick: () => {
                  if (!col.versions?.paperVersionId) {
                    message.error('试卷信息有误，请联系管理员');
                    return;
                  }
                  navigate(`/exam/testpaper/info?id=${col.versions?.paperVersionId}`);
                },
              },
              {
                label: '历史版本',
                onClick: () => {
                  setCurrentData(col);
                  setVersionVisibleTrue();
                },
              },
              {
                label: '预览',
              },
              {
                label: '删除',
                onClick: async () => {
                  await onDeleteDataByIds([col?.paperId || '']);
                  QueryTable.ProviderGlobalDispatch(
                    {
                      type: QueryTable.ActionEnum.REQUEST_START,
                      payload: {},
                    },
                    ExamPaperTableName,
                  );
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <Card className="m-4 rounded-md">
      <div className="text-base font-semibold mb-4">{t('exam.testpaper')}</div>
      <QueryTable.Provider useQuery={false} name={ExamPaperTableName} requestFn={getTestPapers}>
        <QueryTable.Filter schema={schema} components={{ Input }} />
        <QueryTable.Table
          columns={totalColumn}
          rowKey="paperId"
          scroll={{ x: 1200 }}
          tabsNode={
            <Button icon={<AiOutlinePlus />} type="primary" onClick={setTrue}>
              新建试卷
            </Button>
          }
        />
      </QueryTable.Provider>
      <PaperModal
        open={visible}
        onCancel={() => {
          setFalse();
          setCurrentData(null);
        }}
        onRefresh={onRefresh}
        data={currentData}
      />
      <Modal
        open={versionVisible}
        footer={null}
        width={1200}
        title="历史版本"
        onCancel={setVersionVisibleFalse}>
        <QueryTable.Provider
          useQuery={false}
          name={ExamPaperTableVersionName}
          requestFn={getPaperVersion}>
          <QueryTable.Table
            defaultTableHandleActions={[]}
            columns={[
              ...historyColumns,
              {
                title: '操作',
                fixed: 'right',
                width: 30,
                renderType: 'Operation',
                renderProps: (col: IPaperVersionItem) => {
                  return {
                    buttons: [
                      {
                        label: '发布',
                        onClick: async () => {
                          if (!col.paperVersionId) {
                            message.error('试卷信息有误，请联系管理员');
                            return;
                          }
                          await publishVersion(col.paperVersionId);
                          QueryTable.ProviderGlobalDispatch(
                            {
                              type: QueryTable.ActionEnum.REQUEST_START,
                              payload: {},
                            },
                            ExamPaperTableVersionName,
                          );
                          onRefresh();
                        },
                      },
                      {
                        label: '删除',
                        onClick: async () => {
                          if (!col.paperVersionId) {
                            message.error('试卷版本信息有误，请联系管理员');
                            return;
                          }
                          await deletePaperVersion(col?.paperVersionId);
                          QueryTable.ProviderGlobalDispatch(
                            {
                              type: QueryTable.ActionEnum.REQUEST_START,
                              payload: {},
                            },
                            ExamPaperTableVersionName,
                          );
                        },
                      },
                    ],
                  };
                },
              },
            ]}
            rowKey="paperVersionId"
            scroll={{ x: 1200 }}
          />
        </QueryTable.Provider>
      </Modal>
    </Card>
  );
}
